.ms-DatePicker .ms-TextField-field {
    background-color: white;
    border: 1px solid #6a94b0;
}

.ms-DatePicker .ms-TextField-fieldGroup {
    border: none;
}

.ms-DatePicker.error .ms-TextField-field {
    border: 1px solid #a71930;
}

.ms-DatePicker-picker {
    overflow-y: hidden;
}
/* ideally this should be done through the fabric styling system/components */
.content-area h1 {
    font-size: 42px;
    line-height: 50px;
    /* font-family: 'Segoe UI'; */
    font-weight: 300;
}

.content-area h2 {
    font-size: 28px;
    line-height: 36px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}

.content-area h3 {
    font-size: 22px;
    line-height: 29px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}

.content-area h4 {
    font-size: 18px;
    line-height: 24px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}
.numberCircle {
    display:inline-block;

    border-radius:50%;
    border:none;
    background-color: rgb(232, 230, 227);
    font-size:12px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.numberCircle:before,
.numberCircle:after {
    content:'\200B';
    display:inline-block;
    line-height:0px;

    padding-top:50%;
    padding-bottom:50%;
}

.numberCircle:before {
    padding-left:8px;
}
.numberCircle:after {
    padding-right:8px;
}

.primary-background {
    background-color: rgb(25,57,80);
}

.primary-font-color {
    color: rgb(25,57,80);
}
.ms-BasePicker-selectedItems {
    display: flex;
    flex-wrap: wrap;
}

.suggestionsVisible {
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    height: 25em;
    overflow-y: scroll;
    flex: 1 1;
}
.suggestionsVisible > div {
    width: 98%;
}

.selectedSuggestions {
    overflow-y: auto;
    max-height: 25em;
    width: 30em;
    flex: 1 1;
}

.suggestionsHidden {
    height: 0;
    width:0;
    opacity: 0;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
}
.ms-Panel-main-custom-large > div {
    width: 64em;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    padding: 0.4em;
}

.ms-Panel-main-custom-normal > div {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
}
.selection-enabled {
    width: 30px;
    /* transition-duration: 0.1s; */
}

.selection-disabled {
    width: 0px;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    opacity: 0;
}

.item-selected {
    /* background: #FFFFDA !important; */
    background: transparent;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
