.ms-DatePicker .ms-TextField-field {
    background-color: white;
    border: 1px solid #6a94b0;
}

.ms-DatePicker .ms-TextField-fieldGroup {
    border: none;
}

.ms-DatePicker.error .ms-TextField-field {
    border: 1px solid #a71930;
}

.ms-DatePicker-picker {
    overflow-y: hidden;
}