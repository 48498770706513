.selection-enabled {
    width: 30px;
    /* transition-duration: 0.1s; */
}

.selection-disabled {
    width: 0px;
    transition-duration: 0.5s;
    opacity: 0;
}

.item-selected {
    /* background: #FFFFDA !important; */
    background: transparent;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}