.ms-BasePicker-selectedItems {
    display: flex;
    flex-wrap: wrap;
}

.suggestionsVisible {
    transition-duration: 0.2s;
    height: 25em;
    overflow-y: scroll;
    flex: 1;
}
.suggestionsVisible > div {
    width: 98%;
}

.selectedSuggestions {
    overflow-y: auto;
    max-height: 25em;
    width: 30em;
    flex: 1;
}

.suggestionsHidden {
    height: 0;
    width:0;
    opacity: 0;
    transition-duration: 0.2s;
}