/* ideally this should be done through the fabric styling system/components */
.content-area h1 {
    font-size: 42px;
    line-height: 50px;
    /* font-family: 'Segoe UI'; */
    font-weight: 300;
}

.content-area h2 {
    font-size: 28px;
    line-height: 36px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}

.content-area h3 {
    font-size: 22px;
    line-height: 29px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}

.content-area h4 {
    font-size: 18px;
    line-height: 24px;
    /* font-family: 'Segoe UI'; */
    font-weight: 600;
}
.numberCircle {
    display:inline-block;

    border-radius:50%;
    border:none;
    background-color: rgb(232, 230, 227);
    font-size:12px;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.numberCircle:before,
.numberCircle:after {
    content:'\200B';
    display:inline-block;
    line-height:0px;

    padding-top:50%;
    padding-bottom:50%;
}

.numberCircle:before {
    padding-left:8px;
}
.numberCircle:after {
    padding-right:8px;
}

.primary-background {
    background-color: rgb(25,57,80);
}

.primary-font-color {
    color: rgb(25,57,80);
}